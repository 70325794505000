.navbar {
    background-image: linear-gradient(to left, #001246, #07346e, #0b4294);
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    width: 100%;
    z-index: 900;
    top: 0;
    /* min-width: 100vw; */
    backdrop-filter: opacity(0.2);  
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    color: #fff;
    background: none;
  }
  
  .nav-menu {
    z-index: 950;
    /* background-color: #24252d; */
    background-color: #1a1b26;
    width: 420px;
    height: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    box-shadow: #01144c 2px 0px 4px;
    transition: left 1.3s ease-in-out;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    @media (max-width: 800px) {
      width: 100%;
      left: -200%;
    }
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    transition: 1s;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px 0px 0px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 1.1rem;
    padding: 15px 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-shadow: 2px 2px 4px black;
  }
  
  .nav-text a:hover {
    /* background-color: #1a83ff; */
    /* color: linear-gradient(to right, #01144c, #1753a0, #3474d4); */
    color: #3474d4;
      

  }
  
  .nav-menu-items {
    width: 100%;
    font-family: 'Noto Sans Armenian', sans-serif;
    padding: 0;
    margin: 0;
  }
  
  .navbar-toggle {
    background-color: #2d2e39;
    width: 130px;
    height: 100px;
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }